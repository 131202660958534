import React, { Component } from "react";
import ChangeDialog from "./ChangeDialog/ChangeDialog"
import classes from "./Software.css"
import axios from "axios"


class Software extends Component {

  state = {
    production: [],
    testing: [],
    changelog: "",
    version: "",
    showMoreProduction: false,
    showMoreTest: false
  }

  componentDidMount() {
    this.refreshBuilds()
  }

  refreshBuilds = () => {

    // Fetch credentials
    let mode = localStorage.getItem("mode")
    let password = localStorage.getItem("password")

    const auth = {
      username: (mode === "client" ? "client" : "admin"),
      password: password
    }

    // Download decoder versions
    axios.get("https://software.5abox.com/downloads" + this.props.folder + "/BuildLog.json", { auth: auth })
      .then((result) => {
        if (result.data) {

          let production = result.data.production
          let testing = result.data.testing

          // sort to version number
          production.sort((a, b) => (a.version < b.version) ? 1 : -1)
          testing.sort((a, b) => (a.version < b.version) ? 1 : -1)

          this.setState({ production, testing })
        }
      })
  }

  showProduction = () => {
    this.setState({ showMoreProduction: !this.state.showMoreProduction })
  }
  showTesting = () => {
    this.setState({ showMoreTest: !this.state.showMoreTest })
  }

  downloadBuild = (filename) => {

    // Fetch credentials
    let mode = localStorage.getItem("mode")
    let password = localStorage.getItem("password")

    const auth = {
      username: (mode === "client" ? "client" : "admin"),
      password: password
    }

    axios({
    url: "https://software.5abox.com/downloads" + this.props.folder + "/" + filename,
    method: "GET",
    auth: auth,
    responseType: "blob",
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
  }

  deleteBuild = (buildType, version) => {

    if (window.confirm("Are you sure you want to delete this build?\nThis action cannot be undone.")) {

      // Fetch credentials
      let mode = localStorage.getItem("mode")
      let password = localStorage.getItem("password")

      const auth = {
        username: (mode === "client" ? "client" : "admin"),
        password: password
      }

      // Download the current changelog
      axios.get("https://software.5abox.com/downloads" + this.props.folder + "/BuildLog.json", { auth: auth })
        .then((result) => {
          if (result.data) {

            // Remove this version from the BuildLog
            let buildlog = result.data
            buildlog[buildType] = buildlog[buildType].filter((build) => {
                return build.version !== version
            });

            // PUT new BuildLog.json file
            axios.put("https://software.5abox.com/downloads" + this.props.folder + "/BuildLog.json", buildlog, { auth: auth, headers: { "Content-Type": "application/json" } })

            // PUT on the server
            axios.delete("https://software.5abox.com/downloads" + this.props.folder + "/Wizzard" + this.props.folder.at(-1) + "-SRAS-" + version + ".zip", { auth: auth });

            // Update the list
            let production = buildlog.production
            let testing = buildlog.testing

            // sort to version number
            production.sort((a, b) => (a.version < b.version) ? 1 : -1)
            testing.sort((a, b) => (a.version < b.version) ? 1 : -1)

            this.setState({ production, testing })
          }
        })
    }
  }

  changeBuildType = (newBuildType, version) => {

    let explanation = (newBuildType === "production") ? "upgrade this build to production?" : "downgrade this build to testing?"
    let oldBuildType = (newBuildType === "production") ? "testing" : "production"

    if (window.confirm("Are you sure you want to " + explanation)) {

      // Fetch credentials
      let mode = localStorage.getItem("mode")
      let password = localStorage.getItem("password")
      const auth = { username: (mode === "client" ? "client" : "admin"), password: password }

      // Download the current changelog
      axios.get("https://software.5abox.com/downloads" + this.props.folder + "/BuildLog.json", { auth: auth })
        .then((result) => {
          if (result.data) {

            // Get the build version
            let buildlog = result.data
            let buildObject = buildlog[oldBuildType].find((build) => build.version === version)

            // Remove this version from the old BuildLog type
            buildlog[oldBuildType] = buildlog[oldBuildType].filter((build) => {
                return build.version !== version
            });

            // Append to the new BuildLog type
            buildlog[newBuildType].push(buildObject)

            // PUT new BuildLog.json file
            axios.put("https://software.5abox.com/downloads" + this.props.folder + "/BuildLog.json", buildlog, { auth: auth, headers: { "Content-Type": "application/json" } })

            // Update the list
            let production = buildlog.production
            let testing = buildlog.testing
            // sort to version number
            production.sort((a, b) => (a.version < b.version) ? 1 : -1)
            testing.sort((a, b) => (a.version < b.version) ? 1 : -1)
            this.setState({ production, testing })
          }
        })
    }
  }



  render() {

    // MARK: - Decoder downloads
    let productionDownloads = []
    let testDownloads = []

    if (this.state.production) {

      // Loop through all production releases
      let length = this.state.production.length;
      for (var index = 0; index < length; index++) {

        let release = this.state.production[index]
        productionDownloads.push(
          <div className={classes.versionRow} key={release.version}>
            <p className={classes.versionNumber}>Version {release.version}</p>
            <p className={classes.releaseDate}>{release.releaseDate}</p>
            <button className={classes.downloadLink} onClick={() => this.setState({ version: release.version, changelog: release.changelog, showDialog: true })}>Changelog</button>
            <button className={classes.downloadLink} onClick={() => this.downloadBuild("Wizzard" + this.props.folder.at(-1) + "-SRAS-" + release.version + ".zip")}>Download</button>
            {this.props.isMaintainer && <button className={classes.promoteLink} onClick={() => this.changeBuildType("testing", release.version)}>To testing</button>}
            {this.props.isMaintainer && <button className={classes.removeLink} onClick={() => this.deleteBuild("production", release.version)}>Delete</button>}
          </div>)

          // Break if we don't want to show more
          if (index > 4 && this.state.showMoreProduction === false) {
            break
          }
      }

      // Loop through all production releases
      length = this.state.testing.length;
      for (index = 0; index < length; index++) {

        let release = this.state.testing[index]
        testDownloads.push(
          <div className={classes.versionRow} key={release.version}>
            <p className={classes.versionNumber}>Version {release.version}</p>
            <p className={classes.releaseDate}>{release.releaseDate}</p>
            <button className={classes.downloadLink} onClick={() => this.setState({ version: release.version, changelog: release.changelog, showDialog: true })}>Changelog</button>
            <button className={classes.downloadLink} onClick={() => this.downloadBuild("Wizzard" + this.props.folder.at(-1) + "-SRAS-" + release.version + ".zip")}>Download</button>
            {this.props.isMaintainer && <button className={classes.promoteLink} onClick={() => this.changeBuildType("production", release.version)}>To production</button>}
            {this.props.isMaintainer && <button className={classes.removeLink} onClick={() => this.deleteBuild("testing", release.version)}>Delete</button>}
          </div>)

          // Break if we don't want to show more
          if (index > 4 && this.state.showMoreTest === false) {
            break
          }
      }
    }

    return (
      <>
        <div className={classes.container}>

          <div className={classes.downloadContainer}>
            <h1>{this.props.title}</h1>
            <div className={classes.subtitleRow}>
              <h2>Production builds</h2>
              <button className={classes.moreButton} onClick={this.showProduction}>show {this.state.showMoreProduction ? "less" : "all"} builds</button>
            </div>
            <div className={classes.downloadSubcontainer}>
              {productionDownloads}
            </div>
            <div className={classes.subtitleRow}>
              <h2>Test builds</h2>
              <button className={classes.moreButton} onClick={this.showTesting}>show {this.state.showMoreTest ? "less" : "all"} builds</button>
            </div>
            <div className={classes.downloadSubcontainer}>
              {testDownloads}
            </div>
          </div>
        </div>

        <ChangeDialog
          shouldShow={this.state.showDialog}
          version={this.state.version}
          changelog={this.state.changelog}
          closeModal={() => this.setState({ showDialog: false })}/>
      </>
    );
  }
}

export default Software;
