import * as actionTypes from "./actionTypes";
import md5 from "md5"


// Internal actionCreators

const authenticationStarted = () => {
  return { type: actionTypes.AUTH_START };
}

const authenticationSuccess = (token, isMaintainer) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    isMaintainer: isMaintainer,
    token: token
  };
}

const authenticationFail = (error) => {
  return { type: actionTypes.AUTH_FAIL, error: error };
}



// Publicly accessible actionCreators

export const authenticate = (password) => {
  return (dispatch) => {

    // Notify about start
    dispatch(authenticationStarted());

    if (md5(password) === "77e5965337b5d5ac0491002903afed01") {
      // Klant
      dispatch(authenticationSuccess(password, false));
      localStorage.setItem("mode", "client");
      localStorage.setItem("password", password);
    } else if (md5(password) === "79d9d88b1e282c6427b8d7845d4329bf") {
      // Maintainer
      dispatch(authenticationSuccess(password, true));
      localStorage.setItem("mode", "maintainer");
      localStorage.setItem("password", password);
    } else {
      dispatch(authenticationFail("Incorrect password. Contact Filip."))
    }
  }
}


export const logout = () => {
  // Return action
  return { type: actionTypes.AUTH_LOGOUT };
}
