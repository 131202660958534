import React, { Component } from "react";
import Manufacturer from "./Manufacturer/Manufacturer"
import classes from "./Decoders.css"
import axios from "axios"



class Upload extends Component {

  state = {
    supportedDevices: {},
  }

  componentDidMount() {

    // Fetch credentials
    let mode = localStorage.getItem("mode")
    let password = localStorage.getItem("password")

    const auth = {
      username: (mode === "client" ? "client" : "admin"),
      password: password
    }
    
    // Download supported devices
    axios.get("https://software.5abox.com/downloads/SupportedDevices.json", { auth: auth })
      .then((result) => {
        this.setState({ supportedDevices: result.data })
      })
  }


  render() {

    // MARK: - Supported devices
    let devicesContent = []
    if (this.state.supportedDevices) {
      let keys = Object.keys(this.state.supportedDevices)
      const lastIndex = keys.length - 1
      keys.sort()
      for (const [i, key] of keys.entries()) {
        // Construct device list
        let deviceRows = []
        for (let device of this.state.supportedDevices[key]) {
          deviceRows.push(<li key={device}>{device}</li>)
        }
        // Save manufacturer
        devicesContent.push(<Manufacturer manufacturer={key} devices={deviceRows} key={key} firstRow={i === 0} lastRow={i === lastIndex} />)
      }
    }

    return (
      <>
        <div className={classes.container}>

          <div className={classes.downloadContainer}>
            <h1>Supported LoRa devices</h1>
            {devicesContent}
          </div>
        </div>
      </>
    );
  }
}

export default Upload;
