import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./Login.css";
import * as actions from "../../../store/actions/auth";

class Authentication extends Component {

  state = {
    password: null
  }


  // MARK: - Handling

  inputChangeHandler = (newValue, field) => {

    let newState = {...this.state};
    newState[field] = newValue;
    this.setState(newState);
  }

  initiateLogin = (event) => {

    event.preventDefault()
    this.props.authenticate(this.state.password ? this.state.password : "");
  }


  render() {
    let error = (
      <div className={classes.errorContainer}>
        <p>{this.props.authError}</p>
      </div>
    )

    return (
      <div className={classes.pageContainer}>
        <div className={classes.authContainer}>
          <h1 className={classes.signInLabel}>Sign in to 5A Software</h1>
          <form>

            <input
              type="password"
              placeholder="Password"
              autoComplete="current-password"
              className={classes.passwordField}
              onChange={(event) => this.inputChangeHandler(event.target.value, "password")}/>
            <button onClick={(event) => this.initiateLogin(event)} className={classes.signInButton}>Sign in</button>
          </form>
        </div>
        {(this.props.authError != null) ? error : null}
      </div>
    );
  }
}


// MARK: - Redux

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (email, password) => dispatch(actions.authenticate(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
