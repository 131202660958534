import React, { Component } from 'react';
import Layout from "./containers/Layout/Layout";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Software from "./components/Software/Software";
import Upload from "./components/Upload/Upload";
import Decoders from "./components/Decoders/Decoders";


import * as actions from "./store/actions/auth";


class App extends Component {

  componentDidMount() {

  }

  render() {

    // Redirect if necessary
    let redirect = null;
    // If authenticated
    if (this.props.history.location.pathname === "/") {
      redirect = (<Redirect to="/software-version1"/>);
    }

    return (
      <div className="App">
        <Layout
            isAuthenticated={this.props.isAuthenticated}
            isMaintainer={this.props.isMaintainer}
            logout={this.props.logout}>
          <Switch>
            <Route path="/community" component={() => {
              window.open("https://community.5abox.com", "_blank");
              window.open("http://" + window.location.host, "_self");
              return null }}/>
            <Route path="/upload" component={Upload}></Route>
            <Route path="/decoders" component={Decoders}></Route>
            <Route path="/software-version1" key="version1">
              <Software isMaintainer={this.props.isMaintainer} title="CloudWizzard version 1" folder="/version1"/>
            </Route>
            <Route path="/software-version2" key="version2">
              <Software isMaintainer={this.props.isMaintainer} title="CloudWizzard version 2" folder="/version2"/>
            </Route>

          </Switch>
        </Layout>
        {redirect}
      </div>
    );
  }
}


// MARK: - Redux

const mapStateToProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticated, isMaintainer: state.auth.isMaintainer }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
