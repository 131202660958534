import React, { Component } from "react";
import classes from "./Upload.css"
import axios from "axios"



class Upload extends Component {

  state = {
    changelog: "",
    releaseDate: "",
    build: "production",
    version: "",
    file: "",
    family: "version1"
  }

  componentDidMount() {


  }


  // MARK - Handlers

  uploadFile = (event) => {
    this.setState({ file: event.target.files[0] })
  }

  publishBuild = () => {

    // Perform content checks
    const changelogEmpty = this.state.changelog === ""
    const buildEmpty = this.state.build === ""
    const dateEmpty = this.state.releaseDate === ""
    const versionEmpty = this.state.version === ""
    const fileEmpty = this.state.file === ""

    if (changelogEmpty || buildEmpty || versionEmpty || fileEmpty || dateEmpty) {
      alert("One or more fields are empty. Please complete the form.")
      return
    } else {

      // MARK: - Update changelog

      // Fetch credentials
      let mode = localStorage.getItem("mode")
      let password = localStorage.getItem("password")

      const auth = {
        username: (mode === "client" ? "client" : "admin"),
        password: password
      }

      // Download the current changelog
      axios.get("https://software.5abox.com/downloads/" + this.state.family + "/BuildLog.json", { auth: auth })
        .then((result) => {
          if (result.data) {

            let buildlog = result.data
            const newBuild = { version: this.state.version, releaseDate: this.state.releaseDate, changelog: this.state.changelog }
            buildlog[this.state.build].push(newBuild)

            // POST new BuildLog.json file
            axios.put("https://software.5abox.com/downloads/" + this.state.family + "/BuildLog.json", buildlog, { auth: auth, headers: { "Content-Type": "application/json" } })

            // Post the build itself
            const options = { auth: auth, headers: { "Content-Type": "application/zip" } }

            // PUT on the server
            axios.put("https://software.5abox.com/downloads/" + this.state.family + "/Wizzard" + this.state.family.at(-1) + "-SRAS-" + this.state.version + ".zip", this.state.file, options);

            // Clear fields & display feedback
            this.setState({
              changelog: "",
              releaseDate: "",
              build: "production",
              version: "",
              file: "",
            })
            alert("Build uploaded, check Software page.")
          }
        })
    }
  }


  render() {

    return (
      <>
        <div className={classes.container}>

          <div className={classes.downloadContainer}>
            <h1>Upload a new build</h1>

            <div className={classes.uploadForm}>
              <div className={classes.formRow}>
                <div className={classes.label}>Version number</div>
                <input className={classes.versionInput} onChange={(event) => this.setState({ version: event.target.value })} value={this.state.version} type="text" placeholder="E.g. 220101-1200"/>
              </div>

              <div className={classes.formRow}>
                <div className={classes.label}>Release date</div>
                <input className={classes.releaseDateInput} onChange={(event) => this.setState({ releaseDate: event.target.value })} value={this.state.releaseDate} type="text" placeholder="E.g. August 1st 2022"/>
              </div>

              <div className={classes.formRow}>
                <div className={classes.label}>Changelog</div>
                <textarea className={classes.changelogInput} onChange={(event) => this.setState({ changelog: event.target.value })} value={this.state.changelog} type="text" placeholder="E.g. Added a new feature"></textarea>
              </div>

              <div className={classes.formRow}>
                <div className={classes.label}>Build family</div>
                <select className={classes.buildSelect} onChange={(event) => this.setState({ family: event.target.value })} value={this.state.family}>
                  <option value="version1">Version 1</option>
                  <option value="version2">Version 2</option>
                </select>
              </div>

              <div className={classes.formRow}>
                <div className={classes.label}>Build type</div>
                <select className={classes.buildSelect} onChange={(event) => this.setState({ build: event.target.value })} value={this.state.build}>
                  <option value="production">Production build</option>
                  <option value="testing">Test build</option>
                </select>
              </div>

              <div className={classes.formRow}>
                <div className={classes.label}>Upload build</div>
                <input
                  type="file"
                  id="fileInput"
                  className={classes.fileInput}
                  onChange={this.uploadFile}/>
                <label htmlFor="fileInput" className={classes.uploadButton}>Choose file</label>
              </div>

              <button className={classes.publishButton} onClick={this.publishBuild}>Publish build</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Upload;
