import React from "react";
import Modal from "./Modal/Modal";
import classes from "./ChangeDialog.css"

const helpDialog = (props) => {



  return (
    <Modal shouldShow={props.shouldShow} closeModal={props.closeModal}>
      <h3>Changes in release {props.version}</h3>

      <div style={{whiteSpace: "pre-wrap"}} className={classes.tableContainer}>
        {props.changelog}
      </div>
      <button className={classes.closeButton} onClick={props.closeModal}>Close</button>
    </Modal>
  );
}

export default helpDialog;
