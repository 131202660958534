import React from "react"
import NavigationItem from "./NavigationItem/NavigationItem"
import classes from "./NavigationHeader.css"
import Logo from "./5A-logo.png"

const navigationHeader = (props) => {



  return(
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={Logo} alt="5A logo"/>
      </div>
      {props.isAuthenticated ?
      <div className={classes.itemContainer}>
        <NavigationItem title="CloudWizzard 1" page="software-version1"/>
        <NavigationItem title="CloudWizzard 2" page="software-version2"/>
        {props.isMaintainer ? <NavigationItem title="Add build" page="upload"/> : null}
        <NavigationItem title="LoRa decoders" page="decoders"/>
        <NavigationItem title="Community" page="community"/>
      </div> : null}
    </div>
  );
}

export default navigationHeader;
